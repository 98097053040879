import React from 'react'
import ImageWrap from 'components/ImageWrap'
import PageLink from 'components/PageLink'

export default function ImgCopyOpen({ content }) {
  const { image, pretitle, title, body, pageLink, pageLinkText } = content
  return (
    <section className='img-copy  '>
      <div className='img-copy__wrap '>
        <div className='img-copy__img'>
          <ImageWrap image={image} />
        </div>
        <div className='img-copy__copy'>
          <div className='img-copy__copy-wrap'>
            <p className='img-copy__copy__pretitle'>{pretitle}</p>
            <h2 className='img-copy__copy__title'>{title}</h2>

            <div
              className='img-copy__copy__excerpt'
              dangerouslySetInnerHTML={{ __html: body }}
            />
            {pageLink && (
              <PageLink pageLink={pageLink} className='img-copy__cta'>
                {pageLinkText}
              </PageLink>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
