import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import NewsMosaic from 'components/NewsMosaic'
import ImgCopyOpen from 'components/ImgCopyOpen'
import Banner from 'components/Banner'

export default function HomeTemplate({ data }) {
  const page = data.wpPage
  const { newsMosaic } = page
  const imageCopy = page.template.home.imageCopy
  const bannerMid = page?.bannerMid?.bannerMid

  return (
    <Layout
      bodyClassName='home page-template page-template-page-builder page-builder page home-2 app-data index-data singular-data page-data page-127-data page-home-2-data page-builder-data front-page-data'
      page={page}
    >
      <NewsMosaic content={newsMosaic} />
      <ImgCopyOpen content={imageCopy} />
      <Banner content={bannerMid} template='bannerMid' />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...NewsMosaic
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_Home {
          home {
            imageCopy {
              image {
                ...GatsbyImage
              }
              pretitle
              title
              body
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
              pageLinkText
            }
          }
        }
      }
      ...BannerMidPage
    }
  }
`
